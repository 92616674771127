
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ForgotService {
  result:any;
  forgotResult:any;
  constructor(private router: Router,private _http: Http) { }

  forgotPassword(email){
    return this._http.post('auth/forgotpassword',{'email':email}).pipe(map
    (
      (result)=>
       this.result = result.json()
    ));
  }
  forgotPasswordDetails(token){
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // let token=  JSON.parse(localStorage.getItem('MMAdmin'));
     headers.append('x-access-token',token);

     let options = new RequestOptions({ headers: headers });
    return this._http.get('auth/getDetailForgotPassword',options).pipe(map
    (
      (forgotResult)=> this.forgotResult = forgotResult.json()
    ));
  }

  //update the password service call
  forgotPasswordUpdate(email,pwd){
    return this._http.post('auth/forgotPasswordUpdate',{'email':email,'password':pwd}).pipe(map
    (
      (result)=>
       this.result = result.json()
    ));
  }


}
