import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule , ActivatedRoute } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'ng-sidebar';
import { AgmCoreModule } from '@agm/core';




import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModule } from './shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import{ HttpModule } from '@angular/http';
import {InstallReportService} from'./install-report.service';
import { AuthenticationService } from './services/login.service';
import { ClientService } from './services/client.service';
import { UserService } from './services/user.service';
import { TheaterTemplateService } from './services/theater-template.service';
import { ClientUsersService } from './services/client-users.service';
import { TheaterService } from './services/theater.service';
import {ViewTheatersService} from './services/view-theaters.service';
import { ForgotService } from './services/forgot.service';
import { TheaterMappingService } from './services/theatermapping.service';
import { ViewProjectsService } from './services/view-projects.service';
import { PullCoverageService } from './services/pull-coverage.service';

import { AuthGuard_for_Admins, AuthGuard_for_both } from './_guards/auth.guard';

// import {SelectModule} from 'ng2-select';
// import { ComponentComponent } from './component/component.component'; #mahesh
import {AlertService} from './services/alert.service';
import {EmailService} from './services/email.service';
// import {BillingService} from './services/billing.service';
import {AlertComponent} from './alert/alert.component'; 
import {CheckerToolsService} from './services/checker-tools.service';
import {ConfirmationDialogService} from './confirmation-dialog/confirmation-dialog.services'
import {ClientPortalUsageService} from './services/client-portal-usage'


// import { PullCoverageComponent } from './pull-coverage/pull-coverage.component';
// import { ViewProjectsComponent } from './view-projects/view-projects.component';


// import { EditemailTemplateComponent } from './editemail-template/editemail-template.component';
// import { EmailsComponent } from './emails/emails.component';
// import { AddTheaterTemplateComponent } from './add-theater-template/add-theater-template.component';

// import { AddTheaterTempComponent } from './add-theater-temp/add-theater-temp.component';
// import { ViewTheatersComponent } from './view-theaters/view-theaters.component';
// import { TheaterComponent } from './theater/theater.component';

// import { ClientUsersComponent } from './client-users/client-users.component';

// import { TheaterTemplateComponent } from './theater-template/theater-template.component';
// import { UserComponent } from './user/user.component';


//import { DoughnutChartComponent, PieChartComponent, BarChartComponent } from 'angular-d3-charts'; // this is needed!
//import { ReportsComponent } from './reports/reports.component';
//import { MovieComponent } from './movie/movie.component';
//import { MoviedashboardComponent } from './moviedashboard/moviedashboard.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
// import { ServiceWorkerModule } from '@angular/service-worker';
// import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    // ComponentComponent, #mahesh
    AlertComponent,
    
    // ViewProjectsComponent,

    
    // EditemailTemplateComponent,
    // AddTheaterTemplateComponent,
    // AddTheaterTempComponent,
    // ViewTheatersComponent,
    // TheaterComponent,
    // ClientUsersComponent,
    // TheaterTemplateComponent,
    // UserComponent,
    // DoughnutChartComponent, 
    // PieChartComponent, 
    // BarChartComponent
    //ReportsComponent,
   // MovieComponent,
   // MoviedashboardComponent
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
    BrowserAnimationsModule,
    SharedModule,
    HttpModule,
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    NgxDatatableModule,
    // SelectModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgbModule,
    SidebarModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AgmCoreModule.forRoot({apiKey: 'AIzaSyBg_qNVvIPLatfkkSmhUdiONNveDYWZ2IY'})
  ],
  providers: [InstallReportService,AuthenticationService,ClientService,AuthGuard_for_Admins,AuthGuard_for_both,UserService,EmailService,ClientUsersService,ClientPortalUsageService,
    TheaterService,AlertService,ViewTheatersService,TheaterTemplateService,ForgotService,TheaterMappingService,ViewProjectsService,PullCoverageService,CheckerToolsService,ConfirmationDialogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
