
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';


@Injectable()
export class InstallReportService {
  result:any;
  constructor(private _http: Http) { }

  getInstallProjectName(clientId){
      return this._http.post("/projects/getInstallProjectName",{'clientId':clientId}).pipe(
      map(result => this.result = result.json()));
  }

  /*
    Missing Part Data service call
  */

  getMissingPartData(projectId){   
    return this._http.post('/projects/metirialMissingCount',{'project_id':projectId}).pipe(map
      (
        result=>
         this.result = result.json().data
      ));
    }

  
  /*
    Compliance And NonCompliance Data service call
  */

  getComplianceAndNonComplianceData(projectId){   
    return this._http.post('/projects/getCompliantAndNonCompliantCount',{'project_id':projectId}).pipe(map
      (
        result=>
         this.result = result.json().data
      ));
    }

      
  /*
    Non Compliant Analytics Data service call
  */

 getNonCompliantAnalyticsData(projectId){   
    return this._http.post('/projects/nonCompliantAnalytics',{'project_id':projectId}).pipe(map
      (
        result=>
         this.result = result.json().data
      ));
    }

    /*Circuit Complaice Data*/
    
  getCircuitComplianceData(projectId){   
    return this._http.post('/projects/circuitCompliance',{'project_id':projectId}).pipe(map
      (
        result=>
         this.result = result.json().data
      ));
    }

        /*get all project Data*/
    
  getProjectData(projectId){   
    return this._http.post('/projects/getProjectsData',{'project_id':projectId}).pipe(map
      (
        result=>
         this.result = result.json().data
      ));
    }



}
