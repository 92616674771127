
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';
import { json } from '@angular-devkit/core';

@Injectable()
export class ViewTheatersService {
  result:any;
  constructor( private router: Router,private _http: Http) { }

  getTheaterName(template_id){    
    
    return this._http.post('view-theaters/getTheaterNames',{'template_id':template_id}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }

  getTemplateNames(template_id){    
    
    return this._http.post('view-theaters/getTemplateNames',{'template_id':template_id}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }
  removeTheaterIdinTemplate(data){
    return this._http.post('view-theaters/removeTheaterIdinTemplate',JSON.parse((JSON.stringify(data)))).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }
}