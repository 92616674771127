
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class ViewProjectsService {
  result:any;
  constructor( private router: Router,private _http: Http) { }


  getProjectDetails(project_id){      
    return this._http.post('/viewProject/getProjectDetails',{'project_id':project_id}).pipe(map
      ( 
        (result)=>this.result = result.json()
      ));
  }

  

  getProjectExcellFile(project_id){
    return this._http.post('viewProject/getProjectExcellFile',{'project_id':project_id}).pipe(map
    (
      (result)=>
      this.result = result.json()
    ));
}
getCoverageExcellFile(project_id){
  return this._http.post('viewProject/getCoverageExcellFile',{'project_id':project_id}).pipe(map
  (
    (result)=>
    this.result = result.json()
  ));
}
addLatestCoverage(project_id){
  return this._http.post('viewProject/addLatestCoverageDetails',{'project_id':project_id}).pipe(map
  (
    (result)=>
    this.result = result.json()
  ));
}

getCityState(id,type){
  return this._http.post('viewProject/getCityState',{'id':id,'type':type}).pipe(map
  (
    (result)=>
    this.result = result.json()
  ));
}

getchangeCityState(id){
  return this._http.post('viewProject/getchangeCityState',{'id':id}).pipe(map
  (
    (result)=>
    this.result = result.json()
  ));
}

updateMoviemeasureId(id,theater_id,type){
  return this._http.post('viewProject/updateMoviemeasureId',{'id':id,'theater_id':theater_id,'type':type}).pipe(map
  (
    (result)=>
    this.result = result.json()
  ));
}
changeMoviemeasureId(id,theater_id){
  return this._http.post('viewProject/changeMoviemeasureId',{'id':id,'theater_id':theater_id}).pipe(map
  (
    (result)=>
    this.result = result.json()
  ));
}
updateMoviemeasureIdByName(id,theater_id){
  return this._http.post('viewProject/updateMoviemeasureIdByName',{'id':id,'theater_id':theater_id}).pipe(map
  (
    (result)=>
    this.result = result.json()
  ));
}
getAllTheaterNames(type,movie_theaterid){    
  return this._http.post('/viewProject/getAllTheaterNames',{'type':type,'movie_theaterid':movie_theaterid}).pipe(map
    (
      (result)=>this.result = result.json()
    ));
} 
getProjectName(project_id){    
  return this._http.post('/viewProject/getProjectName',{'project_id':project_id}).pipe(map
    (
      (result)=>this.result = result.json()
    ));
}

}  