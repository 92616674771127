import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard_for_Admins, AuthGuard_for_both } from './_guards/auth.guard';
// import { AuthGuard_for_scheduler } from './_guards/auth_scheduler.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./login/login.module').then(m => m.loginModule)
    }]
  },

  {
    path: '',
    canActivate: [AuthGuard_for_both],
    component: AdminLayoutComponent,
    children: [
      {
        path: 'moviedashboard',
        canActivate: [AuthGuard_for_Admins],
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'admin',
        // component: AdminLayoutComponent,
        canActivate: [AuthGuard_for_Admins],
        // loadChildren: './user/user.module#UserModule',
        children: [
          {
            path: 'users',
            canActivate: [AuthGuard_for_Admins],
            loadChildren: () => import('./user/user.module').then(m => m.UserModule)
          },
          {
            path: 'clients',
            canActivate: [AuthGuard_for_Admins],
            loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule)
          },
          {
            path: 'clients/:id/client-users',
            canActivate: [AuthGuard_for_Admins],
            loadChildren: () => import('./client-users/client-users.module').then(m => m.ClientUsersModule)
          },
        ]
      },
      {
        path: 'schedulerTools',
        canActivate: [AuthGuard_for_both],
        children: [
          {
            path: 'theater-template',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./theater-template/theater-template.module').then(m => m.TheaterTemplateModule)
          },
          {
            path: 'theater-template/add-theater-template',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./add-theater-template/add-theater-template.module').then(m => m.AddTheaterTemplateModule)
          },
          {
            path: 'theater-template/:id/view-theaters',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./view-theaters/view-theaters.module').then(m => m.ViewTheatersModule)
          },
          {
            path: 'theater',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./theater/theater.module').then(m => m.TheaterModule)
          },
          {
            path: 'theaterMapping',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./theater-mapping/theater-mapping.module').then(m => m.TheaterMappingModule)
          },
          {
            path: 'theaterMapping/:id/view-projects',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./view-projects/view-projects.module').then(m => m.ViewProjectsModule)
          },
          {
            path: 'pull-coverage',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./pull-coverage/pull-coverage.module').then(m => m.PullCoverageModule)
          },
    
          {
            path: 'CheckerTools',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./checker-tools/checker-tools.module').then(m => m.CheckerToolsModule)
          },
        ]
      },
      {
        path: 'Reports',
        canActivate: [AuthGuard_for_both],
        children: [
          {
            path: 'client_login_report',
            canActivate: [AuthGuard_for_both],
            loadChildren: () => import('./client_login_report/client_login_report.module').then(m => m.ReportModule)
          },
        ]
      },
      {
        path: 'email-template',
        canActivate:[AuthGuard_for_both],
        loadChildren: () => import('./email_template/email_template.module').then(m => m.Email_TemplateModule)
      },

      {
        path: 'client-portal-usage',
        canActivate:[AuthGuard_for_both],
        loadChildren: () => import('./client-portal-usage/client-portal-usage.module').then(m => m.ClientPortalUsageModule)
      },

      
      // {
      //   path: 'editemail/:id/edit-email-template',
      //   canActivate:[AuthGuard],
      //   loadChildren: './editemail-template/editemail-template.module#EditemailTemplateModule'
      // },
      // {
      //   path: 'email/add-email-template',
      //   canActivate:[AuthGuard],
      //   loadChildren: './emails/emails.module#EmailsModule'
      // },
      // {
      //   path: 'billing-report',
      //   canActivate:[AuthGuard],
      //   loadChildren: './billing-report/billing-report.module#BillingReportModule'
      // },
    ]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'error',
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
      }]
  }, {
    path: '**',
    redirectTo: 'error/404'
  }];

