
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class TheaterMappingService {
  result:any;
  constructor( private router: Router,private _http: Http) { }


  getCsvDatas(selectedType,tData,project_name){  

    return this._http.post('/theaterMapping/getCsvData',{'tData':tData,'project_name':project_name}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }

  getTheaterMappingList(){    
    return this._http.get('/theaterMapping/getTheaterMappings').pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 





}  