
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class TheaterService {
  result:any;
  constructor( private router: Router,private _http: Http) { }

  getTheater(inputData){    
    console.log(inputData);
    return this._http.post('/theater/getTheaters',JSON.parse(JSON.stringify(inputData))).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 

  getCoverageDetails(theater_id){    
    return this._http.post('/theater/getCoverageDetails',{'theater_id':theater_id}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 
} 