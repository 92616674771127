
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthenticationService {
  result:any;
  constructor( private router: Router,private _http: Http) { }


  /*
    Missing Part Data service call
  */



  authentication(email,password){   
    const body={'email':email,"password":password};

    let headers = new Headers();
    headers.append('No-Auth','True');
    let options = new RequestOptions({ headers: headers });

    return this._http.post('auth/authenticate',body,options).pipe(map
      (
        (result)=>
         this.result = result.json()
      ));
    }
    getUserDetails(){
      let headers = new Headers();
     // headers.append('Content-Type', 'application/json');
     let token =  localStorage.getItem('MMAdmin') ? JSON.parse(localStorage.getItem('MMAdmin')) : JSON.parse(localStorage.getItem('MMScheduler'));
      headers.append('x-access-token',token);

      let options = new RequestOptions({ headers: headers });
      return this._http.get('auth/me',options).pipe(map
      (
        (result)=>
        this.result = result.json()
      ));
    }

    //for logout service

    logout(){
       // remove user from local storage to log user out
       localStorage.removeItem('MMAdmin');
       localStorage.removeItem('MMScheduler');
       this.router.navigate(['/']);

    }
  }