
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class TheaterTemplateService {
  result:any;
  constructor( private router: Router,private _http: Http) { }

  getTheaters(){    
    return this._http.get('/theaterTemplate/getTheaterList').pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 
  
  getTheaterTemplates(){    
    return this._http.get('/theaterTemplate/getTheaterTemplate').pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 

  getEmails(){
    return this._http.get('/theaterTemplate/getEmailIds').pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }

  getCsvDatas(selectedType,tData,template_name){  

    return this._http.post('/theaterTemplate/getCsvData',{'selectedType':selectedType,'tData':tData,'template_name':template_name}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }

  // getTheaterName(theater_id){    
  //   return this._http.post('/theaterTemplate/'+theater_id+'/view-theaters/getTheaterNames',{'theater_id':theater_id}).map
  //     (
  //       (result)=>this.result = result.json()
  //     );
  // }


  addTheaterTemplate(template_name,theater_ids){    
    return this._http.post('/theaterTemplate/addTheaterTemplates',{'template_name':template_name,'theater_ids':theater_ids}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 

  sendMail(notFoundVariable){    
    return this._http.post('/theaterTemplate/sendmail',{'notFoundVariable':notFoundVariable}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 


  deleteTemplate(data){
    return this._http.post('/theaterTemplate/deleteTemplate',JSON.parse(JSON.stringify(data))).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 


}  