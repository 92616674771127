
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class ClientUsersService {
  result:any;
  constructor( private router: Router,private _http: Http) { }

  addClientUser(firstName,lastName,email,password,phone,client_id){    
    return this._http.post('/client/'+client_id+'/client-users/addClientUser',{'firstName':firstName,'lastName':lastName,'email':email,'password':password,'phone':phone,'client_id':client_id}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 

  getClientUsers(id){    
    return this._http.post('/client/'+id+'/client-users/getClientUsers',{'id': id}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
      
  }

  getSingleClientUser(getData){
    return this._http.post('/client/'+getData.client_id+'/client-users/getSingleClientUser',{'id':getData.id}).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }

  updateClientUser(editForm){
    // console.log(editForm.trailers);
    return this._http.post('/client/'+editForm.client_id+'/client-users/updateClientUser',editForm).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }
  
  deleteClientUser(deleteData){
    return this._http.post('/client/'+deleteData.client_id+'/client-users/deleteClientUser',{'id':deleteData.id}).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }

  updateClientUserPassword(editForm){
    // console.log(editForm.trailers);
    return this._http.post('/client/'+editForm.client_id+'/client-users/updateClientUserPassword',editForm).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }
  showClientUserPassword(id){
    // console.log(editForm.trailers);
    return this._http.post('/client/'+id+'/client-users/showClientUserPassword',{'id': id}).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }




}