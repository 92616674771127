
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

// var mkdirp = require('mkdirp');
declare var require: any

@Injectable()
export class CheckerToolsService {
  result: any;
  constructor(private router: Router, private _http: Http) { }

  getAllTheaters(inputData) {
    return this._http.post('/checkerTools/getAllTheaters', inputData).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }

  getProjectByTheaterId(inputData) {
    return this._http.post('/checkerTools/getProjectByTheaterId', inputData).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }


  findCheckers(inputData) {
    return this._http.post('/checkerTools/findCheckers', inputData).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }

  assignChecker(inputData) {
    return this._http.post('/checkerTools/assignChecker', inputData).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }

  downloadInactiveCheckerDetails(inputDate) {
    return this._http.post('/checkerTools/downloadInactiveCheckerDetails', inputDate).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }

}