import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Location} from '@angular/common';


@Injectable()
export class AuthGuard_for_Admins implements CanActivate {
  constructor(private router: Router,private _location: Location) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (localStorage.getItem('MMAdmin')) {
        // logged in so return true
        return true;
     }
      else if (localStorage.getItem('MMScheduler')) {
        // logged in as scheduler so return false and navigate to previous page
        // this.router.navigate(['/schedulerTools/theater-template']);
        this._location.back();
        // console.log(this._location.path(),'path');
        return false;
      }
     else{
    // not logged in so redirect to login page
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        return false;
     }
     
  }
}

@Injectable()
export class AuthGuard_for_both implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('MMAdmin') || localStorage.getItem('MMScheduler')) {
      // logged in so return true
      return true;
    }
    else{
      // not logged in so redirect to login page
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }
}