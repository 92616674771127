
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class UserService {
  result:any;
  constructor( private router: Router,private _http: Http) { }

  getRoles(){    
    return this._http.get('/user/getRoles').pipe(map
      (
        (result)=>this.result = result.json(),
      ));
  } 

  addUsers(firstName,lastName,email,password,phone,role){    
    return this._http.post('/user/addUser',{'firstName':firstName,'lastName':lastName,'email':email,'password':password,'phone':phone,'role':role}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 

  getUsers(){    
    return this._http.get('/user/getUsers').pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }
  
  deleteUsers(id){
    return this._http.post('/user/deleteUser',{'ID':id}).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }

  // getSingleUsers(id){
  //   return this._http.post('/user/getSingleUser',{'ID':id}).pipe(map
  //   (
  //     (result)=>this.result = result.json()
  //   ));
  // }

  updateUsers(editForm){
    return this._http.post('/user/updateUser',editForm).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }


  

  // showUserPassword(id){
  //   // console.log(id);
  //   return this._http.post('/user/showUserPassword',{'ID':id}).pipe(map
  //   (
  //     (result)=>this.result = result.json()
  //   ));
  // }

  updateUserPassword(editForm){
    return this._http.post('/user/updateUserPassword',editForm).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }

}