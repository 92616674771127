
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

// var mkdirp = require('mkdirp');
// var multer  = require('multer');
// var upload= multer({'dest':'./uploads'});
// var fs = require('fs');
declare var require: any

@Injectable()
export class ClientService {
  result:any;
  constructor( private router: Router,private _http: Http) { }

  getCountryList(){    
    return this._http.get('/client/getCountryList').pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 

  getStateList(){    
    return this._http.get('/client/getStateList').pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 

  addClients(inputData){    
    return this._http.post('/client/addClient',inputData).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  } 

  getClients(){    
    return this._http.get('/client/getClients').pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }
  getClient_login_report(){    
    return this._http.get('/client/getClient_login_report').pipe(map
      (
        (result)=>this.result = result.json(),
        // console.log('inputData',inputData)
      ));
  }
  // getSingleClients(id){
  //   return this._http.post('/client/getSingleClient',{'ID':id}).pipe(map
  //   (
  //     (result)=>this.result = result.json()
  //   ));
  // }

  updateclients(editForm){
    // console.log(editForm.trailers);
    return this._http.post('/client/updateClient',editForm).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }
  
  deleteClients(id){
    return this._http.post('/client/delteClient',{'ID':id}).pipe(map
    (
      (result)=>this.result = result.json()
    ));
  }




}