import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'admin',name: 'Admin',type: 'sub',icon: '',
    children: [
      { state: 'users',name: 'Admin Users',type: 'link',icon: 'basic-life-buoy' },
      { state: 'clients',name: 'Clients',type: 'link',icon: 'basic-server' },
    ]
  },
  {
    state: 'schedulerTools',name: 'Scheduler Tools',type: 'sub',icon: '',
    children: [
      {
        state: 'theater-template',name: 'Theater Template',type: 'link',icon: 'basic-server'
      },
      {
        state: 'theater',name: 'Theaters',type: 'link',icon: 'basic-server'
      },
      {
        state: 'theaterMapping',name: 'Theater Mapping',type: 'link',icon: 'basic-server'
      },
      {
        state: 'pull-coverage',name: 'Pull Coverage',type: 'link',icon: 'basic-server'
      },
      {
        state: 'CheckerTools',name: 'Checker Tools',type: 'link',icon: 'basic-server'
      },
    
    ]
  },
  {
    state: 'Reports',name: 'Reports',type: 'sub',icon: '',
    children: [
      {
        state: 'client_login_report',name: 'Client - Login Report',type: 'link',icon: 'basic-server'
      },
    ]
  },
  { state: 'email-template',name: 'Email Templates',type: 'link',icon: 'basic-accelerator' },
  { state: 'client-portal-usage',name: 'Client Portal Usage',type: 'link',icon: 'basic-server' },
  // {  //   state: '/',  //   name: 'HOME',  //   type: 'link',  //   icon: 'basic-accelerator'  // },
  // {  //     state: 'moviedashboard',  //     name: 'Dashboard',  //     type: 'link',  //     icon: 'basic-gear'  // },
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  add(menu: Menu) {
    // MENUITEMS.push(menu);
  }
}
