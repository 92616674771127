
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class ClientPortalUsageService {
  result:any;
  constructor( private router: Router,private _http: Http) { }



  getData(inputData){    
    return this._http.post('/clientPortalUsage/getData',inputData).pipe(map
      (
        (result)=>this.result = result.json()
      ));
      
  }


  getClients(inputData){    
    return this._http.post('/clientPortalUsage/getClients',inputData).pipe(map
      (
        (result)=>this.result = result.json()
      ));
      
  }


  getLOS(inputData){    
    return this._http.post('/clientPortalUsage/getLOS',inputData).pipe(map
      (
        (result)=>this.result = result.json()
      ));
      
  }


}