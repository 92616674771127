
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';


@Injectable()
export class EmailService {
  result: any;
  constructor(private router: Router, private _http: Http) { }

  getEmailTemplateList() {
    return this._http.get('/emailTemplate/getEmailTemplateList').pipe(map
      (
        (result) => this.result = result.json()
      ));
  }
  getSingleTemplate(id) {
    return this._http.post('/emailTemplate/getSingleTemplate', { 'ID': id }).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }
  viewSingleTemplates(id) {
    return this._http.post('/emailTemplate/viewSingleTemplate', { 'ID': id }).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }
  updateTemplate(editForm) {
    return this._http.post('/emailTemplate/updateTemplates', {
      'email_template_name': editForm.e_t_name,
      'email_template_subject': editForm.e_t_subject, 'email_template_data': editForm.e_t_data, 'id': editForm.id
    }).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }

  addEmailTemplate(templateName, subject, data, alias_Name) {
    return this._http.post('/emailTemplate/addEmailTemplates', { 'templateName': templateName, 'subject': subject, 'data': data, 'aliasName': alias_Name }).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }

  deleteTemplates(id) {
    return this._http.post('/emailTemplate/deleteTemplate', { 'ID': id }).pipe(map
      (
        (result) => this.result = result.json()
      ));
  }

}
