
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class PullCoverageService {
  result:any;
  constructor( private router: Router,private _http: Http) { }


  getCsvDatas(tData){  

    return this._http.post('/theater/getCsvData',{'tData':tData}).pipe(map
      (
        (result)=>this.result = result.json()
      ));
  }

//   getTheaterMappingList(){    
//     return this._http.get('/theaterMapping/getTheaterMappings').map
//       (
//         (result)=>this.result = result.json()
//       );
//   } 





}  